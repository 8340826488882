/* eslint no-console:0 */

import "stylesheets/application.scss"
import "channels"
window.getUserId = function() {
  let userId = localStorage.getItem("customerId")
  if (userId) return userId

  userId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
  localStorage.setItem("customerId", userId)

  return userId
}
